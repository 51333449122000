import React from "react"
import JobForm from "./job-form"
import tikka from "../../content/assets/img/tikka-small.png"
import ReactMarkdown from "react-markdown"


function JobPosition(props){
    let job = props.info;
    // console.log(job);
    return(
        <section className="bot-page__in">
            <div className="bot-section-text">
                <div className="container-800">
                <div className="tar"><img src={tikka} className="feedback-form-tikka" alt="tikka"></img></div>
                <h3>{job.title}</h3>
                {job.description && <ReactMarkdown source={job.description} linkTarget="_blank"/>}
                <div className="mb1">
                {job.location && <div>{props.translation.jobPosition.location}: {job.location}</div>}
                {job.relationship && <div>{props.translation.jobPosition.type}: {job.relationship}</div>}
                {job.date && <div>{props.translation.jobPosition.updated}: {job.date}</div>}

                </div> 
                <JobForm privacy={props.privacy} translation={props.translation} settings={props.settings}></JobForm>
                </div>
            </div>

        </section>
                    )
}


export default JobPosition